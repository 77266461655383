.navbar {
  background-color: #333;
  color: #ccc;
  padding: 1rem;
  z-index: 1010;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-title {
  font-family: "Gochi Hand", system-ui;
  margin: 0;
  font-size: 1rem;
  z-index: 1001;
}
.title1 {
  color: #ef476f;
}

.title2 {
  color: #ffd166;
}

.title3 {
  color: #06d6a0;
}

.navbar-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-left: 1rem;
}

.navbar-links a {
  color: white;
  text-decoration: none;
}

body.no-scroll {
  overflow: hidden;
}

@media (max-width: 768px) {
  .navbar-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    color: #ccc;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .navbar-links.active {
    opacity: 1;
    visibility: visible;
  }

  .navbar-links li {
    margin: 1rem;
    list-style: none;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .navbar-links.active li {
    opacity: 1;
    transform: translateY(0);
  }

  .navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }
}
