.about {
  padding: 2rem 0;
  background-color: #f8f9fa;
}

.about h2 {
  margin-bottom: 1rem;
  color: #333;
}

.about p {
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #6c757d;
}
