.footer {
  background-color: #333;
  padding: 1rem 0;
  margin-top: 2rem;
  color: #fff;
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.social-icons a {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff;
}

.footer p {
  margin: 0;
  font-size: 0.9rem;
  color: #ccc;
}
