.color-palette {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
}

.color-scheme {
  flex: 1;
  min-width: 250px;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.svg-container {
  width: 100%;
  margin-bottom: 1rem;
}

.svg-container svg {
  width: 100%;
  height: auto;
  max-height: 200px;
}

.color-swatch {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  padding: 0 10px;
}

.color-label {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
}

.color-preview {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
}

.color-preview h4 {
  margin-bottom: 0.5rem;
}

.contrast-info {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.copy-button svg {
  transition: transform 0.3s ease;
}

.copy-button:hover svg {
  transform: scale(1.1);
}

@media (max-width: 480px) {
  .color-scheme {
    min-width: 100%;
  }

  .color-swatch {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px;
  }

  .color-label {
    margin-bottom: 5px;
  }

  .copy-button {
    align-self: flex-end;
  }
}
