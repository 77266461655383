.color-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  max-width: 600px;
  width: 100%;
}

.color-input h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.color-input p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.color-input label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.color-preview-picker {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.color-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.color-input-container input[type="text"] {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 0.5rem;
  width: 100%;
  max-width: 200px;
}

.svg-container {
  width: 60px;
  max-width: 600px;
  height: auto;
  margin-top: 1rem;
}

.svg-container svg {
  width: 100%;
  height: auto;
}

.color-picker-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.color-picker-popover {
  position: absolute;
  z-index: 1000;
  top: calc(100% + 10px);
  left: 0;
  margin-top: -15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.react-colorful {
  width: 200px !important;
  height: 200px !important;
}

@media (max-width: 480px) {
  .color-input h2 {
    font-size: 1.2rem;
  }

  .color-input p {
    font-size: 0.9rem;
  }

  .color-input-container input[type="text"] {
    font-size: 0.9rem;
    max-width: 150px;
  }
}
